<template>
    <div class="generator-bottom" :class="{'inactive': !active}">
        <banner-list v-for="(category, i) in bannerCategories"
                     :key="`${category.id}_${i}`"
					 :opened="!validAnchor || category.slug === activeAnchor"
                     :title="category.name">
            <div class="banner__container--left">
                <banner v-for="type in bannerTypes.slice(0, 2)"
                        :image-name="`${category.id}_${type.id}_${lang}.jpg`"
                        ref="banner"
                        :banner-type="type.id"
                        :category="category.slug"
                        :key="`${type.id}_${i}`">
                    <div>{{ type.name }}</div>
                </banner>
            </div>
            <banner v-for="type in bannerTypes.slice(2)"
                    :image-name="`${category.id}_${type.id}_${lang}.jpg`"
                    ref="banner"
                    :banner-type="type.id"
                    :category="category.slug"
                    :key="`${type.id}_${i}`">
                <div>{{ type.name }}</div>
            </banner>
        </banner-list>
    </div>
</template>

<script>
    import BANNER_TYPES from './../utils/bannerTypes';
    import BannerList   from './BannerList';
    import Banner       from './Banner';
    import banner from './Banner.vue';

    export default {
        name:       'GeneratorBottom',
        components: { Banner, BannerList },
		props: {
			activeAnchor: {
				type: String,
				default: null,
			},
		},
        mounted() {
            window.addEventListener('resize', () => {
                this.$refs.banner.forEach((_banner) => _banner.updateSize());
            });
        },
        methods:  {
            generate(hall, booth, lang) {
                this.active = true;
                this.$refs.banner.forEach((_banner) => _banner.generate(hall, booth, lang));
            }
        },
        computed: {
            banner() {
                return banner
            },
            bannerTypes() {
                return BANNER_TYPES;
            },
			validAnchor() {
				// Check if activeAnchor matches any category slug
				return this.bannerCategories.some(category => category.slug === this.activeAnchor);
			},
        },
        data() {
            return {
                lang:             'de',
                active:           false,
                bannerCategories: [
                    {
                      id:   'ag_silver',
                      name: 'Agritechnica Silber',
                      slug: 'agritechnica-silber',
                    },
					{
						id:   'ag_gold',
						name: 'Agritechnica Gold',
						slug: 'agritechnica-gold',
					},
					{
						id:   'ag',
						name: 'Agritechnica',
						slug: 'agritechnica',
					},
					{
						id:   'sc_winner',
						name: 'System & Components Trophy Winner',
						slug: 'System-components-winner',
					},
					{
						id:   'sc_nominee',
						name: 'System & Components Trophy Nominee',
						slug: 'System-components-nominee',
					},
					{
						id:   'sc',
						name: 'System & Components',
						slug: 'System-components',
					},
                ],
            };
        }
    };
</script>
