<template>
    <div class="generator-top">
        <div class="generator-top__text">
            <h2>Banner generator</h2>
            <p>
                Füllen Sie Ihre Hallen- und Standnummer aus, um individuelle Banner für E-Mail, Facebook und Instagram
                zu erhalten.
            </p>
        </div>
        <div class="generator-top__banner-opts-wrapper">
            <form class="generator-top__banner-opts" @submit.prevent="generate" action="#">
                <div class="generator-top__banner-opts-inputs">
                    <span>"Besuchen Sie uns in&nbsp;</span>
                    <span>Halle &nbsp;</span>
                    <input v-model="hallInput" @input="filterAlphanumericInput('hallInput', $event, ['numeric'])" required maxlength="2">
                    <span>&nbsp;, Stand&nbsp;</span>
                    <input v-model="boothInput" @input="filterAlphanumericInput('boothInput', $event, ['numeric', 'alphabetic'])" required maxlength="4">
                    <span>&nbsp; "</span>
                </div>
                <button type="submit" :disabled="!allowSubmit">
                    Banner erstellen
                </button>
            </form>
        </div>
        <div class="generator-top__lang-select" :class="{'inactive': !allowSubmit}">
            <button @click="onLangClick('de')"
                    :class="{'selected': selectedLanguage === 'de'}">
                <img :src="require('../assets/de.png')" alt="Deutschland Flagge">
                Deutsch
            </button>
            <button @click="onLangClick('en')"
                    :class="{'selected': selectedLanguage === 'en'}">
                <img :src="require('../assets/en.png')" alt="US/Uk Flagge">
                English
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name:  'GeneratorTop',
        props: {
            onGenerateClick:  {
                type:     Function,
                required: true,
            },
            onLanguageChange: {
                type:     Function,
                required: true,
            },
        },
        data() {
            return {
                hallInput:        '',
                boothInput:       '',
                selectedLanguage: 'de',
            };
        },
        computed: {
            allowSubmit() {
                return this.hallInput !== '' && this.boothInput !== '';
            },
        },
        methods:  {
            generate() {
                if (this.allowSubmit) {
                    this.onGenerateClick(this.hallInput, this.boothInput, this.selectedLanguage);
                }
            },
            onLangClick(lang) {
                this.selectedLanguage = lang;
                this.onLanguageChange(lang);
                this.$nextTick((() => this.onGenerateClick(this.hallInput, this.boothInput, this.selectedLanguage)));
            },
            filterAlphanumericInput(inputType, event, allowedTypes) {
                let value = event.target.value;
                const filters = {
                    numeric: '0-9',
                    alphabetic: 'a-zA-Z',
                };

                // Create a combined regular expression based on allowed types
                let allowedChars = '';
                if (allowedTypes.includes('numeric')) {
                    allowedChars += filters.numeric;
                  }
                if (allowedTypes.includes('alphabetic')) {
                    allowedChars += filters.alphabetic;
                }

                // Create a regular expression to match disallowed characters
                const disallowedRegex = new RegExp(`[^${allowedChars}]`, 'g');

                // Filter the value to remove disallowed characters
                value = value.replace(disallowedRegex, '');

                // Update the input value
                this[inputType] = value;
          }
        },
    };
</script>
