/*
    Define styles and overwrites for single banners or banner groups
     {
        'images':    ['ed_email_de'], - Images/Banners the style will be applied to
        'fontSize':  23, - font size
        'regular':   true, - add to make text regular instead of bold
        'color':     '#028D37', - text color
        'alignment': 'left', - alignment (left, center, right)
        'position':  [20, 100], - X/Y position of the text,
        'text': {
            'en': 'Visit us in hall ${hall}, booth ${booth}', - overwrite default text for language(s)
        }
    },
*/

export default [
    /*Agritechnica silver*/
    {
        'images': 'ag_silver_email_de',
        'fontSize': 18,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [20, 120],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_silver_email_en',
        'fontSize': 20,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [20, 120],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_silver_facebook_de',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [25, 465],
        'text': {
            'de': 'BESUCHEN SIE\nUNS IN HALLE ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'ag_silver_facebook_en',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [25, 465],
        'text': {
            'en': 'VISIT US IN\nHALL ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'ag_silver_instagram_de',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [550, 1000],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_silver_instagram_en',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [550, 1000],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },


    /*Agritechnica gold*/
    {
        'images': 'ag_gold_email_de',
        'fontSize': 18,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [20, 120],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_gold_email_en',
        'fontSize': 20,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [20, 120],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_gold_facebook_de',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [25, 465],
        'text': {
            'de': 'BESUCHEN SIE\nUNS IN HALLE ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'ag_gold_facebook_en',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [25, 465],
        'text': {
            'en': 'VISIT US IN\nHALL ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'ag_gold_instagram_de',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [550, 1000],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_gold_instagram_en',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [550, 1000],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },


    /*Agritechnica*/
    {
        'images': 'ag_email_de',
        'fontSize': 16,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [300, 121],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_email_en',
        'fontSize': 18,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [300, 121],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_facebook_de',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [25, 465],
        'text': {
            'de': 'BESUCHEN SIE\nUNS IN HALLE ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'ag_facebook_en',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'left',
        'position': [25, 465],
        'text': {
            'en': 'VISIT US IN\nHALL ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'ag_instagram_de',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [550, 1000],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ag_instagram_en',
        'fontSize': 41,
        'color': '#ffffff',
        'alignment': 'center',
        'position': [550, 1000],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },

    /*System Components winner*/
    {
        'images': 'sc_winner_email_de',
        'fontSize': 16,
        'color': '#18408e',
        'alignment': 'center',
        'position': [245, 125],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_winner_email_en',
        'fontSize': 18,
        'color': '#18408e',
        'alignment': 'center',
        'position': [245, 125],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_winner_facebook_de',
        'fontSize': 55,
        'color': '#18408e',
        'alignment': 'center',
        'position': [570, 500],
        'text': {
            'de': 'BESUCHEN SIE UNS\nIN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_winner_facebook_en',
        'fontSize': 55,
        'color': '#18408e',
        'alignment': 'center',
        'position': [600, 530],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_winner_instagram_de',
        'fontSize': 65,
        'color': '#18408e',
        'alignment': 'center',
        'position': [550, 905],
        'text': {
            'de': 'BESUCHEN SIE UNS IN\n HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_winner_instagram_en',
        'fontSize': 65,
        'color': '#18408e',
        'alignment': 'center',
        'position': [550, 905],
        'text': {
            'en': 'VISIT US IN HALL ${hall},\nSTAND ${booth}',
        },
    },

    /*System Components nominee*/
    {
        'images': 'sc_nominee_email_de',
        'fontSize': 16,
        'color': '#18408e',
        'alignment': 'center',
        'position': [245, 125],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_nominee_email_en',
        'fontSize': 18,
        'color': '#18408e',
        'alignment': 'center',
        'position': [245, 125],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_nominee_facebook_de',
        'fontSize': 55,
        'color': '#18408e',
        'alignment': 'center',
        'position': [570, 500],
        'text': {
            'de': 'BESUCHEN SIE UNS\nIN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_nominee_facebook_en',
        'fontSize': 55,
        'color': '#18408e',
        'alignment': 'center',
        'position': [600, 530],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_nominee_instagram_de',
        'fontSize': 65,
        'color': '#18408e',
        'alignment': 'center',
        'position': [550, 905],
        'text': {
            'de': 'BESUCHEN SIE UNS IN\n HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_nominee_instagram_en',
        'fontSize': 65,
        'color': '#18408e',
        'alignment': 'center',
        'position': [550, 905],
        'text': {
            'en': 'VISIT US IN HALL ${hall},\nSTAND ${booth}',
        },
    },

    /*System Components*/
    {
        'images': 'sc_email_de',
        'fontSize': 18,
        'color': '#18408e',
        'alignment': 'left',
        'position': [20, 100],
        'text': {
            'de': 'BESUCHEN SIE UNS IN\nHALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_email_en',
        'fontSize': 18,
        'color': '#18408e',
        'alignment': 'lefz',
        'position': [20, 100],
        'text': {
            'en': 'VISIT US IN HALL ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'sc_facebook_de',
        'fontSize': 50,
        'color': '#18408e',
        'alignment': 'left',
        'position': [50, 435],
        'text': {
            'de': 'BESUCHEN SIE\nUNS IN HALLE ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'sc_facebook_en',
        'fontSize': 50,
        'color': '#18408e',
        'alignment': 'left',
        'position': [50, 435],
        'text': {
            'en': 'VISIT US\nIN HALL ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'sc_instagram_de',
        'fontSize': 55,
        'color': '#18408e',
        'alignment': 'center',
        'position': [550, 935],
        'text': {
            'de': 'BESUCHEN SIE UNS\nIN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'sc_instagram_en',
        'fontSize': 65,
        'color': '#18408e',
        'alignment': 'center',
        'position': [550, 995],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
];
