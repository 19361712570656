<template>
    <div class="banner" @click="onDownloadClick">
        <canvas ref="canvas"></canvas>
        <div class="banner__info">
            <div class="banner__info-meta">
                 <span class="banner__info-name">
                    {{ bannerAttributes.name }}
                </span>
                <span class="banner__info-size">
                    {{ bannerAttributes.size[0] }} x {{ bannerAttributes.size[1] }}
                </span>
            </div>
            <i></i>
        </div>
    </div>
</template>

<script>
    import BANNER_TYPES  from './../utils/bannerTypes';
    import BANNER_STYLES from './../utils/bannerTextstyles';

    export default {
        name:  'Banner',
        props: {
            imageName:  {
                type:     String,
                required: true,
            },
            bannerType: {
                type:    String,
                default: 'email',
            },
            category:   {
                type:    String,
                default: '',
            },
        },
        data() {
            return {
                bannerData: {},
            };
        },
        mounted() {
            this.$nextTick(() => {
                this.updateSize();
            });
        },
        computed: {
            bannerAttributes() {
                return BANNER_TYPES.find((_) => _.id === this.bannerType);
            },
        },
        methods:  {
            updateSize() {
                // set canvas height based on banner ratio and canvas width
                const ratio        = this.bannerAttributes.ratio;
                const canvasRect   = this.$refs.canvas.getBoundingClientRect();
                const canvasWidth  = canvasRect.width;
                const canvasHeight = canvasWidth / ratio;

                // set height on canvas & html el
                this.$refs.canvas.width        = this.bannerAttributes.size[0];
                this.$refs.canvas.height       = this.bannerAttributes.size[1];
                this.$refs.canvas.style.height = `${canvasHeight}px`;

                // generate the banner without text if banner hasn't been created before, else use last text
                if (this.bannerData.hall && this.bannerData.booth && this.bannerData.lang) {
                    this.generate(this.bannerData.hall, this.bannerData.booth, this.bannerData.lang);
                } else {
                    this.generate();
                }
            },
            onDownloadClick() {
                const link     = document.createElement('a');
                const meta     = this.bannerData;
                const filename = `banner-${this.category}-${this.bannerType}-${meta.lang}`;
                link.download  = `${filename}.jpg`;

                // create new canvas and set it to the desired banner size, else we would download in preview size
                const tempCanvas  = document.createElement('canvas');
                tempCanvas.width  = this.bannerAttributes.size[0];
                tempCanvas.height = this.bannerAttributes.size[1];

                // copy the contents of old canvas to temporary one
                const destCtx = tempCanvas.getContext('2d');
                destCtx.drawImage(this.$refs.canvas, 0, 0, tempCanvas.width, tempCanvas.height);

                // generate download link from canvas and remove temporary stuff
                link.href = tempCanvas.toDataURL();
                link.click();
                link.remove();
                tempCanvas.remove();
            },
            fillTextMultiLine(ctx, text, x, y) {
                // create multi-lined text and add line-height
                // https://stackoverflow.com/a/21574562
                const lineHeight = ctx.measureText('M').width * 1.4;
                const lines      = text.split('\n');

                for (let i = 0; i < lines.length; ++i) {
                    ctx.fillText(lines[i], x, y);
                    y += lineHeight;
                }
            },
            generate(hall, booth, lang) {
                let ctx = this.$refs.canvas;

                if (ctx.getContext) {
                    // create new img element
                    const img = document.createElement('img');

                    // set image attributes & get context
                    img.src         = require(`../assets/banners/${this.imageName}`);
                    img.crossOrigin = 'anonymous';
                    ctx             = ctx.getContext('2d');

                    this.bannerData = {
                        lang:  lang,
                        booth: booth,
                        hall:  hall,
                    };

                    img.onload = () => {
                        //draw banner image
                        ctx.drawImage(img, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

                        if (hall && booth) {
                            const rawImageName = this.imageName.split('.')[0];

                            // get styles for text
                            const styles = BANNER_STYLES.find((_) => _.images.indexOf(rawImageName) !== -1);

                            if (!styles) {
                                return console.error(`no banner style found for: ${rawImageName}`);
                            }

                            const bold    = !styles.regular ? 'bold' : '';


                            ctx.font      = `${bold} ${styles.fontSize}px Arial`;
                            ctx.textAlign = styles.alignment;
                            ctx.fillStyle = styles.color;

                            let text = '';

                            // either use default text, or text overwrite defined in banner style
                            if (styles.text && styles.text[lang]) {
                                text = styles.text[lang].replace('${hall}', hall).replace('${booth}', booth);
                            } else {
                                text = lang === 'de'
                                    ? `BESUCHEN SIE UNS\nIN HALLE ${hall}, STAND ${booth}`
                                    : `VISIT US IN\nHALL ${hall}, STAND ${booth}`;
                            }

                            const position = styles.position;

                            // draw text
                            this.fillTextMultiLine(ctx, text, position[0], position[1]);
                        }
                    };

                    img.remove();
                }
            }
        }
    };
</script>
