<template>
    <div id="app">
        <generator-top :on-generate-click="onGenerateClick"
                       :on-language-change="onLanguageChange"/>
        <generator-bottom ref="bannerContainer" :active-anchor="activeAnchor"/>
    </div>
</template>

<script>
    import GeneratorTop    from './components/GeneratorTop';
    import GeneratorBottom from './components/GeneratorBottom';

    export default {
        name: 'App',
        components: { GeneratorBottom, GeneratorTop },
      data() {
        return {
          activeAnchor: null,
        };
      },
        methods:    {
            onGenerateClick(hall, booth, lang) {
                this.$refs.bannerContainer.generate(hall, booth, lang);
            },
            onLanguageChange(lang) {
                this.$refs.bannerContainer.lang = lang;
            },
			checkAnchor() {
				const hash = window.location.hash.replace('#', '');
				this.activeAnchor = hash || null;
			},
        },
		mounted() {
			this.checkAnchor();
			window.addEventListener('hashchange', this.checkAnchor);
		},
		beforeDestroy() {
			window.removeEventListener('hashchange', this.checkAnchor);
		},
    };
</script>
