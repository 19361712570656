export default [
  {
    'id': 'email',
    'name': 'E-Mail Banner',
    'size': [600, 140],
    'ratio': 4.2857142857
  },
  {
    'id': 'facebook',
    'name': 'Facebook Banner',
    'size': [1200, 630],
    'ratio': 1.9047619048
  },
  {
    'id': 'instagram',
    'name': 'Instagram Post',
    'size': [1080, 1080],
    'ratio': 1
  }
];
